
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import globalConfig from "src/config/index";

@Component
export default class Login extends Vue {
  /**
   * 列表数据
   * @private
   * @returns string
   */
  @Prop({ type: Array, required: true })
  private data: any;

  /**
   * 头部
   * @private
   * @returns string
   */
  @Prop({ type: Array, required: true })
  private header: any;

  /**
   * 表格高度(如果设置了fixed则必填)
   * @private
   * @returns string
   */
  @Prop({ default: 560 })
  private height: any;

  /**
   * 是否显示边框，默认有下边框
   * @private
   * @returns string
   */
  @Prop({ type: Boolean, default: true })
  private border: any;

  /**
   * 是否固定
   * @private
   * @returns string
   */
  @Prop({ type: Boolean, default: true })
  private fixed: any;

  /**
   * 第一列是否固定
   * @private
   * @returns string
   */
  @Prop({ type: Boolean, default: true })
  private firstFixed: any;

  /**
   * 是否隔行换色
   * @private
   * @returns string
   */
  @Prop({ type: Boolean, default: false })
  private stripe: string;

  /**
   * 是否显示操作栏
   * @private
   * @returns string
   */
  @Prop({ type: Boolean, default: false })
  private showActions: any;
}
