import { render, staticRenderFns } from "./collect.vue?vue&type=template&id=77bd4073&scoped=true"
import script from "./collect.vue?vue&type=script&lang=ts"
export * from "./collect.vue?vue&type=script&lang=ts"
import style0 from "./collect.vue?vue&type=style&index=0&id=77bd4073&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77bd4073",
  null
  
)

export default component.exports